.tab {
    font-family: "Dejavu Sans";
    width: 360px !important;
    height: 100%;
    background: white;
    border: 1px solid black;
    border-bottom: 0;
    padding: 5px;
    margin: 5px;
    box-shadow: 6px 6px 18px rgba(0, 0, 0, .2);
    color: black;
    line-height: 1.3em;
}

.tabb:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 5px;
    height: 10px;
    width: 360px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 50%, #fff 50%), linear-gradient(-45deg, rgba(255, 255, 255, 0) 50%, #fff 50%);
    background-position: bottom center, bottom center;
    background-size: 0.75rem 0.75rem, 0.75rem 0.75rem;
    background-repeat: repeat-x;
}


.tab .summary {
    display: flex;
    flex-direction: column;
    font-family: "Arial";
}

.tab .logo {
    max-width: 200px;
    text-align: center;
    margin: 5px;
    align-self: center;
}

.tab .title {
    font-size: 24px;
    font-weight: bold;
    margin-left: 4px;
}

.tab .subtitle {
    font-size: 13px;
    font-weight: bold;
    margin-left: 4px;
}

.tab .divider {
    border-top: 1px solid black;
}

.tab .dotted-divider {
    border-top: 1px dashed black;
    border-spacing: 2px;
}

.tab .text {
    font-size: 12px;
}

.tab .pt2 {
    padding-top: 6px;
}

.tab .pd2 {
    padding-bottom: 6px;
}

.tab .pt {
    padding-top: 3px;
}

.tab .pd {
    padding-bottom: 3px;
}


.tab .item {
    display: flex;
    font-size: 13px;
}

.tab .item .name {
    flex: 1;
}

.tab .item .amount {

}

.tab .item .total {
    flex: 1;
    font-weight: bold;
}

.tab .item .total-amount {
    font-weight: bold;
}

